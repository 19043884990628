<template>
  <brand-logos-carousel
    :items="[
      'akur8',
      'pennylane',
      'loreal',
      'doctolib',
      'carrefour',
      'docaposte',
      'morning',
      'orange',
      'malt',
      'jus-mundi',
      'bnpp',
      'stellantis',
    ]"
    :title="$t('brand.customer_logo_banner.default_title')"
  />
</template>
